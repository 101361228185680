import { useContext, useState } from "react";
import { ReportsContext, SectionsType } from "../reports-context";
import { DashboardContext } from "../../../dashboard/dashboard-context";
import Button from "../../../common/components/button";
import { Breakfast } from "../pages/breakfast";
import { RecipesSection } from "../pages/recipesSection";

import { Drinks } from "../pages/drinks";
import CSS from "./common.module.css";
import { FoodsToAvoid } from "../pages/foods-to-avoid";
import { PatientInformation } from "../pages/patient-information";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";
import { LunchAndDinner } from "../pages/lunchAndDinner";

export const Sidebar = () => {
  const { activeSectionReport, setActiveSectionReport } =
    useContext(ReportsContext);
  const { setActiveSection } = useContext(DashboardContext);

  return (
    <div className={CSS.sidebar}>
      <div className={CSS.sidebarNavigation}>
        <span
          data-active={activeSectionReport === "Client-Information"}
          onClick={() => {
            setActiveSectionReport("Client-Information");
          }}
          className={CSS.sidebarButton}
        >
          Client Information
        </span>
        <span
          data-active={activeSectionReport === "Breakfast"}
          onClick={() => {
            setActiveSectionReport("Breakfast");
          }}
          className={CSS.sidebarButton}
        >
          Breakfast
        </span>

        <span
          data-active={activeSectionReport === "LunchAndDinner"}
          onClick={() => {
            setActiveSectionReport("LunchAndDinner");
          }}
          className={CSS.sidebarButton}
        >
          Lunch and Dinner
        </span>
        <span
          onClick={() => {
            setActiveSectionReport("Recipes");
          }}
          data-active={activeSectionReport === "Recipes"}
          className={CSS.sidebarButton}
        >
          Recipes
        </span>
        <span
          onClick={() => {
            setActiveSectionReport("Foods-To-Avoid");
          }}
          data-active={activeSectionReport === "Foods-To-Avoid"}
          className={CSS.sidebarButton}
        >
          Foods to avoid
        </span>

        <span
          onClick={() => {
            setActiveSectionReport("Drinks");
          }}
          data-active={activeSectionReport === "Drinks"}
          className={CSS.sidebarButton}
        >
          Drinks
        </span>
      </div>
      <div className={CSS.buttonRow}>
        <Button
          onClick={() => {
            setActiveSection("Home");
          }}
          label="Close"
        />
      </div>
    </div>
  );
};

type NavButtonProps = {
  activeSection: SectionsType;
  setNextSection: () => void;
  setPreviousSection: () => void;
};
export const NavButtons = ({
  activeSection,
  setNextSection,
  setPreviousSection,
}: NavButtonProps) => {
  const isLastSection = activeSection === "Foods-To-Avoid";

  const { setShowModal } = useContext(ReportsContext);

  return (
    <div className={CSS.navButtons}>
      <span>
        {activeSection !== "Client-Information" && (
          <Button
            label="Back"
            onClick={() => {
              setPreviousSection();
            }}
          />
        )}
      </span>
      <Button
        label={isLastSection ? "Save" : "Next"}
        onClick={() => {
          debugger;
          setNextSection();
          if (isLastSection) {
            setShowModal(false);
          }
        }}
        type="button"
      />
    </div>
  );
};

export const CollapsableDiv = ({
  title,
  children,
}: {
  title: string;
  children: any;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={CSS.collapsableDivContainer} data-isOpen={isOpen}>
      <div
        className={CSS.collapsableDivHeader}
        onClick={() => setIsOpen(!isOpen)}
        data-isOpen={isOpen}
      >
        {isOpen ? <ChevronUpIcon size={32} /> : <ChevronDownIcon size={32} />}
        <p>{title}</p>
      </div>
      <div data-isOpen={isOpen} className={CSS.collapsableDivBody}>
        {isOpen && children}
      </div>
    </div>
  );
};

// This function is used to render the section based on the active section

export function renderSection(section: string) {
  switch (section as SectionsType) {
    case "Client-Information":
      return <PatientInformation />;
    case "Breakfast":
      return <Breakfast />;
    case "LunchAndDinner":
      return <LunchAndDinner />;
    case "Recipes":
      return <RecipesSection />;
    case "Drinks":
      return <Drinks />;
    case "Foods-To-Avoid":
      return <FoodsToAvoid />;
    default:
      return <Breakfast />;
  }
}
