import React, { useContext, useEffect, useState } from "react";
import { InputField, LabelText } from "../../common/components";
import Button from "../../common/components/button";
import CSS from "./reports.module.css";
import { AddReport } from "./pages/sections";
import { DashboardContext } from "../../dashboard/dashboard-context";

import { ReportsContext, ReportsProvider } from "./reports-context";
import { AppContext } from "../../app-context/app-context";
import {
  DownloadIcon,
  HeartPulseIcon,
  MailIcon,
  TrashIcon,
} from "lucide-react";
import { IconButton } from "../../common/components/icon-button";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ReportToPDF } from "../../dashboard/pdf-helpers";

export const ReportsHome = () => {
  return (
    <ReportsProvider>
      <AllReports />
    </ReportsProvider>
  );
};

export const AllReports = () => {
  const { allReports: reports } = useContext(AppContext);

  const [filteredReports, setFilteredReports] = useState(reports);

  const { setEditReport, sendEmail } = useContext(DashboardContext);
  const { showModal, setShowModal } = useContext(ReportsContext);

  //CLearing any edit patient data to be on the safe side
  useEffect(() => {}, []);

  return (
    <>
      <div className={CSS.form}>
        <div className={CSS.allRecipesHeader}>
          <span className={CSS.formField}>
            <LabelText text="Search" />
            <InputField
              width={400}
              placeholder="Search for reports (Client Name or Blood group)"
              onChange={(value) => {
                if (value.target.value && value.target.value !== "") {
                  const filtered = filteredReports;
                  setFilteredReports(filtered);
                } else setFilteredReports(reports);
              }}
            />
          </span>
          <Button
            size="large"
            label="New Report"
            onClick={() => {
              setShowModal(true);
              setEditReport(undefined);
            }}
            children={<HeartPulseIcon color="black" />}
          />
        </div>
        <table>
          <thead>
            <tr>
              <th>Sr#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Age</th>
              <th>Blood Type</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Address</th>
              <th>Referred By</th>
              <th>Client Since</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredReports.map((report, index) => (
              <tr
                key={report.id}
                onClick={() => {
                  setEditReport(report);
                  setShowModal(true);
                }}
              >
                <td>{index + 1}</td>
                <td>{report.patient.firstName}</td>
                <td>{report.patient.lastName}</td>
                <td>{report.patient.age}</td>
                <td>{report.patient.bloodType}</td>
                <td>{report.patient.phoneNumber}</td>
                <td>{report.patient.email}</td>
                <td>{report.patient.address}</td>
                <td>{report.patient.referredBy}</td>
                <td>{report.patient.patientSince + ""}</td>
                <td
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <span className={CSS.iconsContainer}>
                    <IconButton
                      icon={
                        <PDFDownloadLink
                          document={
                            <ReportToPDF template="Template1" report={report} />
                          }
                          fileName={`${report.id}-${report.patient.firstName}-report.pdf`}
                        >
                          <DownloadIcon />
                        </PDFDownloadLink>
                      }
                      onClick={() => {}}
                    />
                    <IconButton icon={<TrashIcon />} onClick={() => {}} />
                    <IconButton
                      icon={<MailIcon />}
                      onClick={() => {
                        sendEmail(report);
                      }}
                    />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showModal && <AddReport />}
    </>
  );
};
