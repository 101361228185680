import React, { useContext, useEffect } from "react";
import { ReportsContext, ReportsProvider } from "../reports-context";

import CSS from ".././reports.module.css";
import { Form, Formik } from "formik";
import { ReportType } from "../../../../types/reports";

import Modal from "../../../common/modal";
import { samplePatients } from "../../../../data/sample-patients";
import { NavButtons, renderSection, Sidebar } from "../common";
import { DashboardContext } from "../../../dashboard/dashboard-context";
import {
  AddReport as AddReportAPI,
  UpdateReport,
} from "../../../../api/reports-api";
import { toast } from "react-toastify";

export type IngredientType = { value: string; label: string };

export const darkThemeStyles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "#333", // Dark background
    color: "#172B4D", // Text color
    borderColor: "#555", // Lighter border color
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: "#333", // Dark background for the dropdown
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#555" : "#333", // Different background for selected option
    color: "white", // Text color
    "&:hover": {
      backgroundColor: "#555", // Background for hovering
    },
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: "#555", // Background for selected options tags
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: "white", // Text color for selected options tags
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: "white", // Text color for the remove icon in selected options tags
    "&:hover": {
      backgroundColor: "#777", // Background color for the remove icon on hover
      color: "white", // Text color for the remove icon on hover
    },
  }),
};

type Mode = "add" | "edit";

export const ReportSections = () => {
  const { activeSectionReport, sections, setActiveSectionReport, setPatient } =
    useContext(ReportsContext);

  const setNextSection = () => {
    const currentIndex = sections.findIndex(
      (section) => section.name === activeSectionReport
    );
    if (currentIndex < sections.length - 1) {
      setActiveSectionReport(sections[currentIndex + 1].name);
    }
  };

  const setPreviousSection = () => {
    const currentIndex = sections.findIndex(
      (section) => section.name === activeSectionReport
    );
    if (currentIndex > 0) {
      setActiveSectionReport(sections[currentIndex - 1].name);
    }
  };

  const { editReport } = useContext(DashboardContext);

  const pageMode: Mode = editReport ? "edit" : "add";

  const initialValues: ReportType = {
    patient: samplePatients[0],
    id: "123",
    breakfast: {
      instructions: [{ title: "", details: "", duration: 0 }],
      recipes: [],
    },
  };

  //Todo: Move this logic somewhere else

  useEffect(() => {
    if (pageMode === "edit" && editReport) {
      setPatient && setPatient(editReport.patient);
    }
  }, [editReport]);

  return (
    <Formik
      initialValues={
        pageMode === "edit" && editReport ? editReport : initialValues
      }
      onSubmit={(values: ReportType) => {
        if (pageMode === "edit") {
          UpdateReport(values);
          toast.success("Report Updated");
        } else {
          AddReportAPI(values);
          toast.success("Report Added");
        }
      }}
    >
      {({ submitForm }) => (
        <Form className={CSS.formContainer}>
          <h2>{activeSectionReport}</h2>
          <div className={CSS.horizontalDivider} />
          <div className={CSS.sectionContainer}>
            {renderSection(activeSectionReport)}
          </div>
          <NavButtons
            activeSection={activeSectionReport}
            setNextSection={
              activeSectionReport !== "Foods-To-Avoid"
                ? setNextSection
                : submitForm
            }
            setPreviousSection={setPreviousSection}
          />
        </Form>
      )}
    </Formik>
  );
};

export const AddReport = () => {
  return (
    <Modal>
      <div className={CSS.wizard}>
        <div className={CSS.header}>
          <label>Report</label>
        </div>
        <div className={CSS.body}>
          <Sidebar />
          <div className={CSS.content}>
            <ReportSections />
          </div>
        </div>
      </div>
    </Modal>
  );
};
