import React, { createContext, useState, ReactNode, FC } from "react";
import { Patient } from "../../../types/patients";

export type SectionsType =
  | "Client-Information"
  | "Breakfast"
  | "LunchAndDinner"
  | "Recipes"
  | "Drinks"
  | "Foods-To-Avoid"
  | "Fruits"
  | "Nutts-And-Seeds"
  | "Oils-And-Seasoning"
  | "Supplements";

type SectionObjectType = {
  name: SectionsType;
  index: number;
};

type ReportsContextType = {
  activeSectionReport: SectionsType;
  setActiveSectionReport: (section: SectionsType) => void;
  sections: SectionObjectType[];
  patient?: Patient;
  setPatient?: (patient: Patient) => void;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

const initialSections: SectionObjectType[] = [
  { name: "Client-Information", index: 0 },
  { name: "Breakfast", index: 1 },
  { name: "LunchAndDinner", index: 2 },
  { name: "Recipes", index: 3 },
  { name: "Foods-To-Avoid", index: 4 },
  { name: "Drinks", index: 5 },
  // { name: "Fruits", index: 4 },
  // { name: "Nutts-And-Seeds", index: 5 },
  // {
  //   name: "Oils-And-Seasoning",
  //   index: 6,
  // },
  // { name: "Supplements", index: 7 },
];
const initialContext: ReportsContextType = {
  activeSectionReport: "Breakfast",
  setActiveSectionReport: () => {},
  sections: initialSections,
  patient: undefined,
  setPatient: () => {},
  showModal: false,
  setShowModal: () => {},
};

export const ReportsContext = createContext<ReportsContextType>(initialContext);

export const ReportsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [activeSectionReport, setActiveSectionReport] =
    useState<SectionsType>("Client-Information");

  const [patient, setPatient] = useState<Patient | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);

  return (
    <ReportsContext.Provider
      value={{
        activeSectionReport,
        setActiveSectionReport,
        sections: initialSections,
        patient,
        setPatient,
        showModal,
        setShowModal,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};
