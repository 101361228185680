import React, { useContext } from "react";
import Button from "../../common/components/button";
import { InputField } from "../../common/components/index";
import { LabelText } from "../../common/components/index";
import { Dropdown } from "../../common/components/index";

import { Field, FieldArray, Form, Formik } from "formik";
import CSS from "./patients.module.css";
import { Patient } from "../../../types/patients";
import { DashboardContext } from "../../dashboard/dashboard-context";

import {
  AddPatient as AddPatientAPI,
  UpdatePatient,
} from "../../../api/patients-api";
import { toast } from "react-toastify";

export const AddPatient = () => {
  const { setActiveSection, editPatient } = useContext(DashboardContext);
  return (
    <Formik
      initialValues={editPatient ? editPatient : ({} as Patient)}
      onSubmit={(values: Patient) => {
        if (editPatient) {
          UpdatePatient(values);
          setActiveSection("Client Records");
          toast.success("Patient updated successfully");
        } else {
          AddPatientAPI(values);
          setActiveSection("Client Records");
          toast.success("Patient added successfully");
        }
      }}
    >
      {({ values }) => (
        <Form
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={CSS.form}>
            <span className={CSS.formField}>
              <LabelText text="First Name" />
              <Field as={InputField} name="firstName" />
            </span>
            <span className={CSS.formField}>
              <LabelText text="Last Name" />
              <Field as={InputField} name="lastName" />
            </span>
            <span className={CSS.formRow}>
              <span className={CSS.formField}>
                <LabelText text="Age" />
                <Field width={120} name="age" as={InputField} />
              </span>
              <span className={CSS.formField}>
                <LabelText text="Blood Type" />
                <Field
                  name="bloodType"
                  label="blood"
                  options={["A", "B", "AB", "O"]}
                  as={Dropdown}
                />
              </span>
              <span className={CSS.formField}>
                <LabelText text="Weight" />
                <Field width={120} name="weight" as={InputField} />
              </span>
            </span>

            <span className={CSS.formRow}>
              <span className={CSS.formField}>
                <LabelText text="Phone Number" />
                <Field width={240} name="phoneNumber" as={InputField} />
              </span>

              <span className={CSS.formField}>
                <LabelText text="Email" />
                <Field width={240} name="email" as={InputField} />
              </span>
              <span className={CSS.formField}>
                <LabelText text="Address" />
                <Field name="address" as={InputField} />
              </span>
            </span>

            <span className={CSS.formField}>
              <LabelText text="Referred By" />
              <Field name="referredBy" as={InputField} />
            </span>

            <div className={CSS.horizontalDivider} />
            <span className={CSS.formField}>
              <LabelText text="History" />

              <table>
                <thead>
                  <tr>
                    <th>Sr#</th>
                    <th>Name</th>
                    <th>Duration Days (Optional)</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <FieldArray
                    name="history"
                    render={(arrayHelpers) => (
                      <>
                        {values.history?.map((history, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <Field
                                name={`history[${index}].name`}
                                as={InputField}
                                placeholder="e.g. Cough, Cold, Fever etc."
                              />
                            </td>
                            <td>
                              <Field
                                name={`history[${index}].duration`}
                                as={InputField}
                                placeholder="(Days)"
                              />
                            </td>
                            <td className={CSS.tableButton}>
                              <Button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                mode="secondary"
                                label="Remove"
                              />
                            </td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan={4} className={CSS.tableButton}>
                            <Button
                              onClick={() =>
                                arrayHelpers.push({ name: "", duration: "" })
                              }
                              mode="primary"
                              label="+"
                            />
                          </td>
                        </tr>
                      </>
                    )}
                  />
                </tbody>
              </table>

              {/* <Field name="history" as={InputField} /> */}
            </span>
            <span className={CSS.formField}>
              <LabelText text="Diagnosis" />
              <Field multiline name="diagnosis" as={InputField} />
            </span>
            <span className={CSS.formField}>
              <LabelText text="Allergies" />
              <Field name="allergies" as={InputField} />
            </span>
          </div>
          <span className={CSS.buttonsRow}>
            {editPatient ? (
              <Button type="submit" label="Update Client" />
            ) : (
              <Button type="submit" label="Add Client" />
            )}

            <Button
              mode="secondary"
              label="Cancel"
              onClick={() => {
                setActiveSection("Client Records");
              }}
            />
          </span>
        </Form>
      )}
    </Formik>
  );
};
