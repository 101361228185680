import { Field, FieldArray } from "formik";
import { FormikContext, FormikContextType } from "formik";
import { useContext, useState } from "react";
import { ReportType } from "../../../../types/reports";
import { darkThemeStyles, IngredientType } from "./sections";
import { TableRow } from "../../../common/components/table";
import { InputField, LabelText } from "../../../common/components";
import Button from "../../../common/components/button";
import Select from "react-select";

import CSS from "./sections.module.css";
import { ingredientsData } from "../../../../data/sample-data";
import { CollapsableDiv } from "../common";
import { DashboardContext } from "../../../dashboard/dashboard-context";

export const LunchAndDinner = () => {
  const { values, setFieldValue } =
    useContext<FormikContextType<ReportType>>(FormikContext);

  const { editReport } = useContext(DashboardContext);

  const [selectedTags, setSelectedTags] = useState<IngredientType[]>(
    editReport?.breakfast.ingredients?.map((tag) => ({
      value: tag,
      label: tag,
    })) ?? []
  );
  return (
    <>
      <CollapsableDiv title="Instructions">
        <FieldArray
          name="lunchAndDinner.instructions"
          render={(arrayHelpers) => (
            <div>
              {values.lunchAndDinner &&
                values.lunchAndDinner.instructions?.map(
                  (instruction, index) => (
                    <div key={index}>
                      <TableRow
                        columns={[
                          { data: index + 1, width: 3 },
                          {
                            child: (
                              <span>
                                <Field
                                  name={`lunchAndDinner.instructions[${index}].title`}
                                  as={InputField}
                                  placeholder="e.g. Eat early salad"
                                />
                              </span>
                            ),
                            width: 15,
                          },
                          {
                            child: (
                              <span>
                                <Field
                                  name={`lunchAndDinner.instructions[${index}].details`}
                                  as={InputField}
                                  placeholder="(Optional) Eat a salad before 7 pm"
                                />
                              </span>
                            ),
                            width: 45,
                          },
                          {
                            child: (
                              <span>
                                <Field
                                  name={`lunchAndDinner.instructions[${index}].duration`}
                                  as={InputField}
                                  placeholder="(Days)"
                                />
                              </span>
                            ),
                            width: 10,
                          },
                          {
                            child: (
                              <span className={CSS.tableButton}>
                                <Button
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)}
                                  mode="secondary"
                                  label="Remove"
                                />
                              </span>
                            ),
                            width: 10,
                          },
                        ]}
                      />
                    </div>
                  )
                )}

              <div className={CSS.tableButton}>
                <Button
                  onClick={() =>
                    arrayHelpers.push({
                      title: "",
                      instructions: "",
                      duration: 0,
                    })
                  }
                  mode="primary"
                  label="+"
                />
              </div>
            </div>
          )}
        />
      </CollapsableDiv>
      <span className={CSS.formField}>
        <LabelText text="Ingredients" />
        <Select
          isMulti
          onChange={(value) => {
            setSelectedTags(value as IngredientType[]);
            setFieldValue(
              "lunchAndDinner.ingredients",
              (value as IngredientType[]).map((ingredient) => ingredient.value)
            );
          }}
          options={ingredientsData}
          styles={darkThemeStyles}
          value={selectedTags.length > 0 ? selectedTags : undefined}
        />
      </span>
    </>
  );
};
