import { APP_VERSION, ApplicationVersion } from "./app-meta-deta";

// Import the Patient type to ensure we are working with the correct data structure
import { samplePatients } from "../data/sample-patients";
import { Patient } from "../types/patients";

const STORAGE_KEY = "patients";

export function initializePatients() {
  const storedPatients = localStorage.getItem(STORAGE_KEY);
  if (!storedPatients) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(samplePatients));
  }
}

export function GetAllPatients(): Patient[] {
  initializePatients();
  const storedPatients = localStorage.getItem(STORAGE_KEY);
  return storedPatients ? JSON.parse(storedPatients) : [];
}

export function AddPatient(newPatient: Patient): void {
  const patients = GetAllPatients();
  patients.push(newPatient);
  localStorage.setItem(STORAGE_KEY, JSON.stringify(patients));
}

export function GetPatientById(patientId: string): Patient | undefined {
  const patients = GetAllPatients();
  return patients.find((patient) => patient.id === patientId);
}
export function UpdatePatient(updatedPatient: Patient): void {
  const patients = GetAllPatients();
  const index = patients.findIndex(
    (patient) => patient.id === updatedPatient.id
  );
  patients[index] = updatedPatient;
  localStorage.setItem(STORAGE_KEY, JSON.stringify(patients));
}
export function DeletePatient(patientId: string): void {
  const patients = GetAllPatients();
  const index = patients.findIndex((patient) => patient.id === patientId);
  patients.splice(index, 1);
  localStorage.setItem(STORAGE_KEY, JSON.stringify(patients));
}
