import { Field, FieldArray } from "formik";
import { FormikContext, FormikContextType } from "formik";
import { useContext, useState } from "react";
import { ReportType } from "../../../../types/reports";
import Modal from "../../../common/modal";
import { TableRow } from "../../../common/components/table";
import { InputField } from "../../../common/components";
import Button from "../../../common/components/button";

import CSS from "./sections.module.css";
import { CollapsableDiv } from "../common";
import { Recipes } from "../../recipes";

export const RecipesSection = () => {
  const { values, setFieldValue } =
    useContext<FormikContextType<ReportType>>(FormikContext);

  const [showModal, setShowModal] = useState(false);
  const [recipeIndex, setRecipeIndex] = useState<number | undefined>(undefined);

  return (
    <>
      {showModal && (
        <div>
          <Modal
            onClose={() => setShowModal(false)}
            onCloseText="Close Recipes"
            variant="small"
          >
            <Recipes
              path={`recipes[${recipeIndex}]`}
              setFieldValue={setFieldValue}
              onClose={() => setShowModal(false)}
            />
          </Modal>
        </div>
      )}

      <CollapsableDiv title="Recipes">
        <FieldArray
          name="recipes"
          render={(arrayHelpers) => (
            <div>
              {values.recipes?.map((recipe, index) => (
                <div key={index}>
                  {!recipe.id ? (
                    <div className={CSS.tableRowButton}>
                      <span
                        onClick={() => {
                          setShowModal(true);
                          setRecipeIndex(index);
                        }}
                        className={CSS.textButton}
                      >
                        Select a recipe
                      </span>
                      <span className={CSS.tableButton}>
                        <Button
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                          mode="secondary"
                          label="Remove"
                        />
                      </span>
                    </div>
                  ) : (
                    <TableRow
                      columns={[
                        { data: index + 1, width: 3 },
                        { data: recipe.name, width: 15 },
                        {
                          data: recipe.ingredients
                            .map(
                              (ingredient) =>
                                `${ingredient.quantity}  ${
                                  ingredient.unit ?? ""
                                } - ${ingredient.ingredientName}
                              `
                            )
                            .join(", "),
                          width: 30,
                        },
                        {
                          data: recipe.instructions ?? "-",
                          width: 25,
                        },
                        {
                          child: (
                            <span>
                              <Field
                                name={`lunch.recipes[${index}].duration`}
                                as={InputField}
                                placeholder="(Days)"
                              />
                            </span>
                          ),
                          width: 10,
                        },
                        {
                          child: (
                            <span className={CSS.tableButton}>
                              <Button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                mode="secondary"
                                label="Remove"
                              />
                            </span>
                          ),
                          width: 10,
                        },
                      ]}
                    />
                  )}
                  {/* {!recipe.id && (
                      <Modal onClose={() => {}}>
                        <ViewRecipes />
                      </Modal>
                    )} */}
                </div>
              ))}

              <div className={CSS.tableButton}>
                <Button
                  onClick={() => arrayHelpers.push({})}
                  mode="primary"
                  label="+"
                />
              </div>
            </div>
          )}
        />
      </CollapsableDiv>
    </>
  );
};
