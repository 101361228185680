import { FC, useContext, useState } from "react";
import { CollapsableDiv } from "../common";
import CSS from "../reports.module.css";
import { LabelText } from "../../../common/components";
import Select from "react-select";

import { ingredientsData } from "../../../../data/sample-data";

import { darkThemeStyles, IngredientType } from "./sections";
import { FormikContext, FormikContextType } from "formik";
import { ReportType } from "../../../../types/reports";
import { DashboardContext } from "../../../dashboard/dashboard-context";

export const FoodsToAvoid: FC = () => {
  const { values, setFieldValue } =
    useContext<FormikContextType<ReportType>>(FormikContext);

  const { editReport } = useContext(DashboardContext);

  const [selectedTags, setSelectedTags] = useState<IngredientType[]>(
    editReport?.breakfast.ingredients?.map((tag) => ({
      value: tag,
      label: tag,
    })) ?? []
  );

  const [selectedFruitsAndVegetables, setSelectedFruitsAndVegetables] =
    useState<IngredientType[]>(
      editReport?.avoids?.vegetablesAndFruits.map((tag) => ({
        value: tag,
        label: tag,
      })) ?? []
    );

  const [selectedMeats, setSelectedMeats] = useState<IngredientType[]>(
    editReport?.avoids?.meat.map((tag) => ({
      value: tag,
      label: tag,
    })) ?? []
  );

  const [selectedSeafood, setSelectedSeafood] = useState<IngredientType[]>(
    editReport?.avoids?.seafood.map((tag) => ({
      value: tag,
      label: tag,
    })) ?? []
  );

  const [selectedOthers, setSelectedOthers] = useState<IngredientType[]>(
    editReport?.avoids?.others.map((tag) => ({
      value: tag,
      label: tag,
    })) ?? []
  );

  return (
    <CollapsableDiv title="Foods to avoid">
      <span className={CSS.formField}>
        <LabelText text="Fruits & Vegetables" />
        <Select
          isMulti
          options={ingredientsData.filter(
            (ingredient) =>
              ingredient.label.toLocaleLowerCase().includes("fruit") ||
              ingredient.label.toLocaleLowerCase().includes("vegetable")
          )}
          styles={darkThemeStyles}
          value={selectedFruitsAndVegetables}
          onChange={(value) => {
            setSelectedFruitsAndVegetables(value as IngredientType[]);
            setFieldValue(
              "avoids.vegetablesAndFruits",
              (value as IngredientType[]).map((ingredient) => ingredient.value)
            );
          }}
        />
      </span>
      <span className={CSS.formField}>
        <LabelText text="Meats" />
        <Select
          isMulti
          options={ingredientsData.filter((ingredient) =>
            ingredient.label.toLocaleLowerCase().includes("meat")
          )}
          styles={darkThemeStyles}
          value={selectedMeats}
          onChange={(value) => {
            setSelectedMeats(value as IngredientType[]);
            setFieldValue(
              "avoids.meat",
              (value as IngredientType[]).map((ingredient) => ingredient.value)
            );
          }}
        />
      </span>
      <span className={CSS.formField}>
        <LabelText text="Seafood" />
        <Select
          isMulti
          options={ingredientsData.filter((ingredient) =>
            ingredient.label.toLocaleLowerCase().includes("seafood")
          )}
          styles={darkThemeStyles}
          value={selectedSeafood}
          onChange={(value) => {
            setSelectedSeafood(value as IngredientType[]);
            setFieldValue(
              "avoids.seafood",
              (value as IngredientType[]).map((ingredient) => ingredient.value)
            );
          }}
        />
      </span>
      <span className={CSS.formField}>
        <LabelText text="Others" />
        <Select
          isMulti
          options={ingredientsData.filter(
            (ingredient) =>
              !ingredient.label.toLocaleLowerCase().includes("meat") &&
              !ingredient.label.toLocaleLowerCase().includes("seafood") &&
              !ingredient.label.toLocaleLowerCase().includes("fruit") &&
              !ingredient.label.toLocaleLowerCase().includes("vegetable")
          )}
          styles={darkThemeStyles}
          value={selectedOthers}
          onChange={(value) => {
            setSelectedOthers(value as IngredientType[]);
            setFieldValue(
              "avoids.others",
              (value as IngredientType[]).map((ingredient) => ingredient.value)
            );
          }}
        />
      </span>
    </CollapsableDiv>
  );
};
