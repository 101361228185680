import { initializePatients } from "./patients-api";
import { initializeRecipes } from "./recipes-api";
import { initializeReports } from "./reports-api";

export const ApplicationVersion = "1.0.0";
export const APP_VERSION = "appVersion";

export const checkApplicationVersion = () => {
  const appVersion = localStorage.getItem(APP_VERSION);

  if (!appVersion || appVersion !== ApplicationVersion) {
    localStorage.clear();
    localStorage.setItem(APP_VERSION, ApplicationVersion);
    initializePatients();
    initializeRecipes();
    initializeReports();
    return true;
  } else {
    return false;
  }
};
