import { useContext, useEffect, useState } from "react";
import { ReportsContext } from "../reports-context";
import Modal from "../../../common/modal";
import CSS from "../reports.module.css";
import { InputField, LabelText } from "../../../common/components";
import Select from "react-select";
import { RecipeTagsOptions } from "../../../../types/recipes";
import { darkThemeStyles } from "./sections";
import { Field, useFormikContext } from "formik";

import { ReportType } from "../../../../types/reports";
import { DashboardContext } from "../../../dashboard/dashboard-context";
import { Patients } from "../../patients";

export const PatientInformation = () => {
  const { patient, setPatient } = useContext(ReportsContext);
  const [showModal, setShowModal] = useState(false);

  const { setFieldValue, values } = useFormikContext<ReportType>();
  const { editReport } = useContext(DashboardContext);

  // Todo: rename this type and move somewhere else
  type IngredientType = { value: string; label: string };

  const [selectedTags, setSelectedTags] = useState<IngredientType[]>(
    editReport?.diagnosis?.map((tag) => ({
      value: tag.diagnosisName,
      label: tag.diagnosisName,
    })) ?? []
  );

  useEffect(() => {
    setShowModal(false);
    setFieldValue("patient", patient);
  }, [patient]);

  return (
    <>
      <div className={CSS.selectButtonContainer}>
        <span>
          {patient && (
            <h3>
              Name: {patient.firstName} {patient.lastName} -{patient.age} Y -
              {patient.bloodType}
            </h3>
          )}
        </span>
        {!editReport && (
          <span>
            <span
              onClick={() => {
                setShowModal(true);
              }}
              className={CSS.textButton}
            >
              {!patient ? "Select Client" : "Change Client"}
            </span>
          </span>
        )}
      </div>
      {showModal && (
        
          <Modal
            onClose={() => setShowModal(false)}
            onCloseText="Close"
            variant="small"
          >
            <Patients setPatient={setPatient} pageMode="patient-report" />
          </Modal>
        
      )}

      {patient && (
        <>
          <span className={CSS.basicField}>
            <LabelText text="Diagnostics or Findings" />
            <Select
              isMulti
              onChange={(value) => {
                setSelectedTags(value as IngredientType[]);

                setFieldValue(
                  "diagnosis",
                  (value as IngredientType[]).map((selectedTag) => ({
                    diagnosisName: selectedTag.label,
                    intensity:
                      values.diagnosis?.find(
                        (ing) => ing.diagnosisName === selectedTag.label
                      )?.intensity ?? 0,
                    remarks:
                      values.diagnosis?.find(
                        (ing) => ing.diagnosisName === selectedTag.label
                      )?.remarks ?? "",
                  }))
                );
              }}
              options={RecipeTagsOptions}
              styles={darkThemeStyles}
              value={selectedTags.length > 0 ? selectedTags : undefined}
            />
          </span>
          <div className={CSS.ratingTable}>
            <table>
              <thead>
                <tr>
                  <th>Sr#</th>
                  <th>Tag</th>
                  <th>Intensity</th>
                  <th>Remarks (Optional)</th>
                </tr>
              </thead>
              <tbody>
                {selectedTags?.map((tag, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{tag.label}</td>
                    <td>
                      <Field
                        as={InputField}
                        type="number"
                        name={`diagnosis[${index}].intensity`}
                        placeholder="(1-6)"
                      />
                    </td>
                    <td>
                      <Field
                        as={InputField}
                        name={`diagnosis[${index}].remarks`}
                        placeholder="Remarks"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <span className={CSS.basicField}>
            <LabelText text="Notes" />
            <Field multiline placeholder="" as={InputField} name="notes" />
          </span>
        </>
      )}
    </>
  );
};
