import { RecipesData } from "../data/sample-data";
import { Recipe } from "../types/recipes";
import { APP_VERSION, ApplicationVersion } from "./app-meta-deta";

const STORAGE_KEY = "recipes";

export function initializeRecipes() {
  const appVersion = localStorage.getItem(APP_VERSION);

  if (!appVersion || appVersion !== ApplicationVersion) {
    localStorage.removeItem(STORAGE_KEY);
    localStorage.setItem(APP_VERSION, ApplicationVersion);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(RecipesData));
  } else {
    const storedRecipes = localStorage.getItem(STORAGE_KEY);
    if (!storedRecipes) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(RecipesData));
    }
  }
}

export function GetAllRecipes() {
  initializeRecipes();
  const storedRecipes = localStorage.getItem(STORAGE_KEY);
  return storedRecipes ? JSON.parse(storedRecipes) : [];
}

export function AddRecipe(newRecipe: Recipe) {
  const recipes = GetAllRecipes();
  recipes.push(newRecipe);
  localStorage.setItem(STORAGE_KEY, JSON.stringify(recipes));
}

export function GetRecipeById(recipeId: string) {
  const recipes = GetAllRecipes();
  return recipes.find((recipe: Recipe) => recipe.id === recipeId);
}

export function UpdateRecipe(updatedRecipe: Recipe) {
  const recipes = GetAllRecipes();
  const index = recipes.findIndex(
    (recipe: Recipe) => recipe.id === updatedRecipe.id
  );
  recipes[index] = updatedRecipe;
  localStorage.setItem(STORAGE_KEY, JSON.stringify(recipes));
}

export function DeleteRecipe(recipeId: string) {
  const recipes = GetAllRecipes();
  const index = recipes.findIndex((recipe: Recipe) => recipe.id === recipeId);
  recipes.splice(index, 1);
  localStorage.setItem(STORAGE_KEY, JSON.stringify(recipes));
}
