import React, { useContext, useEffect, useState } from "react";
import CSS from "./patients.module.css"; // Ensure correct path to your CSS module

import Button from "../../common/components/button";
import { InputField, LabelText } from "../../common/components";
import { DashboardContext } from "../../dashboard/dashboard-context";
import { Patient } from "../../../types/patients";
import { AppContext } from "../../app-context/app-context";
import { IconButton } from "../../common/components/icon-button";
import { Pen, PlusIcon } from "lucide-react";

type AllPatientsProps = {
  pageMode?: "all-patients" | "patient-report";
  setPatient?: (patient: Patient) => void;
};

export const Patients = ({
  pageMode = "all-patients",
  setPatient,
}: AllPatientsProps) => {
  const { allPatients: patients } = useContext(AppContext);
  const [filteredPatients, setFilteredPatients] = useState(patients);
  const { setActiveSection, setEditPatient } = useContext(DashboardContext);

  //CLearing any edit patient data to be on the safe side
  useEffect(() => {
    setEditPatient(undefined);
  }, []);

  return (
    <div className={CSS.form}>
      <div className={CSS.allRecipesHeader}>
        <span className={CSS.formField}>
          <LabelText text="Search" />
          <InputField
            width={400}
            placeholder="Search for clients (First name, Last name, Blood Type, Age, Phone number)"
            onChange={(value) => {
              if (value.target.value && value.target.value !== "") {
                const filtered = patients.filter(
                  (patient) =>
                    patient.firstName
                      .toLowerCase()
                      .includes(value.target.value.toLowerCase()) ||
                    patient.lastName
                      .toLowerCase()
                      .includes(value.target.value.toLowerCase()) ||
                    patient.age === +value.target.value ||
                    patient.bloodType === value.target.value ||
                    patient.phoneNumber === value.target.value
                );
                setFilteredPatients(filtered);
              } else setFilteredPatients(patients);
            }}
          />
        </span>

        {pageMode !== "patient-report" && (
          <Button
            label="Add Client"
            size="large"
            onClick={() => {
              setActiveSection("add-client");
            }}
            children={<PlusIcon color="black" />}
          />
        )}
      </div>
      <div className={CSS.container}>
        <table>
          <thead>
            <tr>
              <th>Sr#</th>
              <th>First Name</th>
              <th>Last Name</th>

              <th>Age</th>
              <th>Blood Type</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Address</th>
              <th>Referred By</th>
              <th>Client Since</th>
              {pageMode !== "patient-report" && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {filteredPatients.map((patient, index) => (
              <tr
                key={patient.id}
                onClick={() => {
                  if (pageMode === "patient-report") {
                    setPatient && setPatient(patient);
                  } else {
                    setActiveSection("patient-details");
                    setEditPatient && setEditPatient(patient);
                  }
                }}
              >
                <td>{index + 1}</td>
                <td>{patient.firstName}</td>
                <td>{patient.lastName}</td>
                <td>{patient.age}</td>
                <td>{patient.bloodType}</td>
                <td>{patient.phoneNumber}</td>
                <td>{patient.email}</td>
                <td>{patient.address}</td>
                <td>{patient.referredBy}</td>
                <td>01-01-2021</td>
                {pageMode !== "patient-report" && (
                  <td
                    onClick={(e: { stopPropagation: () => void }) => {
                      e.stopPropagation();
                    }}
                  >
                    <IconButton
                      text="Edit"
                      icon={<Pen size={18} />}
                      onClick={() => {
                        setActiveSection("edit-client");
                        setEditPatient && setEditPatient(patient);
                      }}
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
